import '@/App.css';
import { ThemeProvider } from '@/components/material';
import { useRouter } from '@/hooks/useRouter';
import theme from '@/theme';
import { RouterProvider } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { router } = useRouter();

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <ToastContainer
        containerId="custom-toast-container"
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  );
}

export default App;
