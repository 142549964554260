import CircularProgress from '@/components/CircularProgress/CircularProgress';
import styles from '@/pages/PageLoading/styles.module.less';
import type { FC } from 'react';

const PageLoading: FC = () => (
  <div data-testid="page-loading" className={styles.container}>
    <div className={styles.wrapper}>
      <CircularProgress />
    </div>
  </div>
);

export default PageLoading;
